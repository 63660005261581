// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-dentist-js": () => import("./../../../src/pages/dentist.js" /* webpackChunkName: "component---src-pages-dentist-js" */),
  "component---src-pages-find-dentist-js": () => import("./../../../src/pages/find-dentist.js" /* webpackChunkName: "component---src-pages-find-dentist-js" */),
  "component---src-pages-group-dso-js": () => import("./../../../src/pages/group-dso.js" /* webpackChunkName: "component---src-pages-group-dso-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-js": () => import("./../../../src/pages/patient.js" /* webpackChunkName: "component---src-pages-patient-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

